@import "styles/common.module.scss";

.container {
	width: 100%;
	//margin-top: 38px;
}

.overlay {
	z-index: 100;
}

.tabs {
	position: relative;
	overflow-x: hidden;

	.tabList {
		position: fixed;
		left: 70px;
		top: 118px;
		padding: 0 0 0 8px;
		height: fit-content;
		z-index: 4;
		width: 100%;
		margin: 0 0 20px 0;

		@media screen and (max-width: 1024px) {
			position: relative;
			top: 0;
			left: 0;
		}
	}

	.tabPane {
		overflow-x: hidden;
	}

	.targetListWarning {
		font-family: "Inter", sans-serif;
		font-size: 0.75rem;
		font-weight: 500;
		padding: 10px;
		display: flex;
		flex-direction: row;
		color: rgba(0, 0, 0, 0.75);
		justify-content: flex-start;
		align-items: center;

		.warningIcon {
			width: 50px;
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-left: 5px;
		}
	}
}

.leftIcon {
	cursor: pointer;
}

.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.leftBadgeContainer {
	position: relative;
	color: #000;
	font-size: 14px;
	left: 15vw;
	top: 83px;
	z-index: 1;

	@media #{$viewportMobileS} {
		left: 5vw;
	}
	@media #{$viewportMobile} {
		left: 5vw;
	}
	@media #{$viewportMobileX} {
		left: 0vw;
	}
	@media #{$viewportSmall} {
		left: 0vw;
	}
	@media #{$viewportTablet} {
		left: 10vw;
	}
	@media #{$viewportMinLarge} {
		left: 100px;
	}
}

.rightBadgeContainer {
	position: relative;
	color: #000;
	font-size: 14px;
	right: -47vw;
	top: 83px;
	z-index: 1;

	@media #{$viewportMobileS} {
		left: 25vw;
	}
	@media #{$viewportMobile} {
		left: 25vw;
	}
	@media #{$viewportMobileX} {
		left: 30vw;
	}
	@media #{$viewportSmall} {
		left: 30vw;
	}
	@media #{$viewportMedium} {
		left: 30vw;
	}
	@media #{$viewportTablet} {
		left: 51vw;
	}
	@media #{$viewportMinLarge} {
		left: 520px;
	}
}

.tabContainer {
	display: flex;
	flex-direction: row;
	//padding-bottom: 50px;
	padding-top: 105px;

	@media screen and (max-width: 1024px) {
		flex-direction: column;
		padding-top: 20px;
	}
}

.iconLink {
	margin-left: 10px;
}

.commentButton {
	margin-left: 20px;
}

.targetTabArea {
	width: 100%;
}

.listedItems {
	width: 100%;
}

.quickViewContainer {
	max-width: 1000px;
	background: white;
	position: relative;

	@media #{$viewportTablet} {
		width: 90% !important;
	}

	.closeIcon {
		position: absolute;
		right: 32px;
		top: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #000000;
		cursor: pointer;
	}

	.loaderContainer {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	.candidateDetailsBlock {
		margin-top: 20px;
	}
}

.applyFilter {
	color: $mainThemeColor;

	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
}

.selectContainer {
	display: flex;
	margin-bottom: 24px;
	margin-top: 92px;
}

.select {
	margin-left: auto;
	width: 200px;
}
