.container {
	display: flex;
	flex-direction: column;
	padding-bottom: 50px;

	@media screen and (max-width: 1480px) {
		margin-top: 10px;
	}
	@media screen and (max-width: 1300px) {
		margin-top: 10px;
	}
	@media screen and (max-width: 1024px) {
		margin-top: 10px;
	}

	&__card {
		background: #fff;
		box-shadow: 0 9px 31px 0 rgba(0, 0, 0, 0.04);
		border-radius: 4px;
		margin-bottom: 1rem;
		padding: 8px 16px;
	}
}
