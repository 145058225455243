.container {
	overflow: hidden;
	border-radius: 4px;
	background: var(--system-bw-white, #fff);
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);

	h2 {
		font-size: 18px;
		font-weight: 500;
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		line-height: 28px;
		margin-left: 20px;
	}

	h3 {
		color: var(--Brand-Neutral-Grey-400, #868d8d);
		font-size: 18px;
		font-weight: 500;
		line-height: 28px;
		margin-left: 20px;

		span {
			color: var(--Brand-Neutral-Grey-600-B, #4e5555);
			font-weight: 600;
		}
	}

	hr {
		height: 1px;
		background: rgba(238, 237, 236, 1);
		border: none;
		margin: 0 0 20px 0;
		width: 100%;
	}

	.chartBox {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		gap: 20px;

		@media screen and (max-width: 1280px) {
			width: 96%;
		}

		@media screen and (max-width: 1279px) {
			flex-direction: column;
		}

		hr {
			background: #eeedec;
			height: 1px;
		}

		.titleSection {
			display: flex;
			justify-content: space-between;
			margin-right: 30px;

			@media screen and (max-width: 800px) {
				flex-direction: column;
				gap: 20px;
			}

			p {
				color: var(--Brand-Neutral-Grey-800, #2c3030);
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 28px;
				padding-left: 30px;
			}
		}

		.frequencyMovement {
			border-radius: 4px;
			border: 1px solid var(--System-Silver-Chalice-100, #eeedec);
			background: var(--system-bw-white, #fff);
			width: 50%;
			padding: 10px 0;

			@media screen and (max-width: 1024px) {
				width: 65%;
				padding: 0;
			}
			@media screen and (max-width: 700px) {
				width: 370px;
				padding: 0;
			}
		}

		.internationalMovements {
			border-radius: 4px;
			border: 1px solid var(--System-Silver-Chalice-100, #eeedec);
			background: var(--system-bw-white, #fff);
			width: 50%;
			padding: 10px 0;

			@media screen and (max-width: 1024px) {
				width: 65%;
				padding: 0;
			}
			@media screen and (max-width: 700px) {
				width: 370px;
				padding: 0;
			}
		}
	}
}

.barChartTitle {
	color: var(--Brand-Neutral-Grey-600-B, #4e5555);
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
}

.average {
	color: var(--Brand-Neutral-Grey-400, #868d8d);
	text-align: right;
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	align-items: center;
	display: inline-flex;
	gap: 5px;

	span {
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 13px;
		font-style: normal;
		font-weight: 600;
		line-height: 18px;
	}
}

.gaugeChartContainer {
	width: fit-content;
	margin: 80px auto 0 auto;
	text-align: center;
}

.gaugeChart {
	display: flex;
	justify-content: flex-start;
	gap: 1px;
	align-items: center;

	.center {
		margin-top: -60px;
		margin-left: -33px;
	}
	.right {
		margin-top: 1px;
		margin-left: -34px;
	}
}

.gaugeLabels {
	position: relative;
	top: -10px;
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	color: #666;
}

.gaugeLabelLeft {
	margin-left: -230px;
	white-space: pre-wrap;
}

.gaugeLabelCenter {
	margin-left: -70px;
	margin-top: -180px;
}

.gaugeLabelRight {
	margin-left: 96px;
}

.gaugeLabelLeft,
.gaugeLabelCenter,
.gaugeLabelRight {
	position: absolute;
	width: 33%;
	color: rgba(78, 85, 85, 1);
	text-align: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 14px;
}

.gaugeTitle {
	margin-top: 10px;
	font-size: 16px;
	font-weight: bold;
	color: #555;
}

.needle {
	position: relative;
	bottom: 0;
	left: -50%;
	width: 0;
	background-color: #333;
	transform-origin: bottom;
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: transform 0.8s ease-in-out;
}

.description {
	color: var(--Brand-Neutral-Grey-600-B, #4e5555);
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	margin-left: 30px;
}
