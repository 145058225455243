.wrapper {
	margin-top: calc(100px + 10px);
	border-radius: 4px;
	background: var(--system-bw-white, #fff);
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);

	h2 {
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 28px;
		border-bottom: 1px solid #eeedec;
		padding: 24px;
		margin: 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.container {
		border-radius: 4px;
		background: var(--system-bw-white, #fff);
		box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);
		display: flex;
		justify-content: flex-start;
		padding: 24px;
		flex-direction: column;
		gap: 24px;

		.info {
			display: flex;
			flex-direction: row;
			gap: 8px;
			align-items: center;
			padding: 4px 8px;
			border-radius: 4px;
			border: 1px solid var(--System-Silver-Chalice-100, #eeedec);

			color: var(--Brand-Neutral-Grey-800, #2c3030);
			font-size: 13px;
			font-style: normal;
			font-weight: 500;
			line-height: 18px; /* 138.462% */
		}

		.cards {
			display: flex;
			flex-direction: row;
		}

		@media screen and (max-width: 1024px) {
			display: grid;
			grid-template-columns: auto auto;
			gap: 20px;
		}

		@media screen and (max-width: 800px) {
			display: grid;
			grid-template-columns: auto;
		}

		.requirementSection {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 10px;
			width: 25%;

			@media screen and (max-width: 1024px) {
				width: 100%;
			}

			.contentBlock {
				display: flex;
				flex-direction: column;
				gap: 3px;

				.title {
					color: var(--Brand-Neutral-Grey-400, #868d8d);
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px;
				}

				.subtitle {
					display: flex;
					flex-direction: column;

					color: var(--Brand-Neutral-Grey-800, #2c3030);
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px;

					&.default {
						color: var(--Brand-Neutral-Grey-300, #b0b5b4);
					}

					&:hover {
						cursor: pointer;
					}
				}

				.count {
					margin-left: 5px;
					border-radius: 4px;
					background: var(--System-Silver-Chalice-100, #eeedec);
					color: var(--Brand-Neutral-Grey-800, #2c3030);
					padding: 3px 8px;
					text-align: center;
					font-size: 12px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px;
				}
			}
		}
	}
}
