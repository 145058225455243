$market-size-color: rgba(218, 216, 215, 1);
$company-color: rgba(131, 125, 124, 1);
$industry-color: rgba(216, 168, 100, 1);
$headCount-color: rgba(113, 160, 170, 1);
$keywords-color: rgba(146, 127, 187, 1);
$languages-color: rgba(147, 187, 97, 1);
$deal-experience-color: rgba(223, 180, 62, 1);
$dark-text: rgba(44, 48, 48, 1);
$light-text: rgba(247, 248, 248, 1);

.container {
	overflow: hidden;
	border-radius: 4px;
	background: var(--system-bw-white, #fff);
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);
	margin-bottom: 30px;

	h2 {
		font-size: 18px;
		font-weight: 500;
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		line-height: 28px;
		margin-left: 20px;
		width: fit-content;
	}

	h3 {
		color: var(--Brand-Neutral-Grey-400, #868d8d);
		font-size: 18px;
		font-weight: 500;
		line-height: 28px;
		margin-left: 20px;

		span {
			color: var(--Brand-Neutral-Grey-600-B, #4e5555);
			font-weight: 600;
		}
	}

	hr {
		height: 2px;
		background: rgba(238, 237, 236, 1);
		border: none;
		margin: 0 0 20px 0;
		width: 100%;
	}

	.headerSection {
		display: flex;
		justify-content: space-between;
		align-items: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-right: 30px;

		@media screen and (max-width: 1200px) {
			width: 720px;
		}

		@media screen and (max-width: 800px) {
			width: 340px;
		}

		.editFilterButton {
			display: inline-flex;
			padding: 9px 16px;
			justify-content: center;
			align-items: center;
			gap: 8px;
			border-radius: 100px;
			border: 1px solid var(--Brand-Primary-600-B, #0c5850);
			color: var(--Brand-Primary-600-B, #0c5850);
			background: transparent;
			width: fit-content;
		}
	}
}

.criteriaBreakdown {
	display: flex;
	gap: 2rem;
	padding: 20px;
	font-family: Inter, serif;
	width: 100%;

	@media screen and (max-width: 1200px) {
		flex-direction: column;
	}
}

/* Funnel Section */
.funnel {
	width: 30%;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-items: center;
	gap: 10px;

	@media screen and (max-width: 1200px) {
		width: 100%;
	}
}

.funnelSegment {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.funnelContainer {
	height: 64px;
	width: 340px;
}

.funnel1SquareSection {
	height: 34px;
	background-color: $market-size-color;
}

.funnel1 {
	border-top: 30px solid $market-size-color;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	height: 0;
	color: $dark-text;
}

.funnel2 {
	width: 300px;
	margin-left: 20px;
	border-top: 64px solid $company-color;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	color: $light-text;
}

.funnel3 {
	width: 250px;
	margin-left: 45px;
	border-top: 44px solid $industry-color;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	color: $light-text;
}

.funnel3 .extendedSection {
	background-color: $industry-color;
	height: 20px;
	width: 100%;
	position: absolute;
	top: -20px;
	z-index: 1;
}

.funnelSquare {
	width: 200px;
	margin-left: 70px;
	height: 64px;
	color: $light-text;
}

.funnelEnd {
	width: 200px;
	margin-left: 70px;
	height: 64px;
	background-color: $deal-experience-color;
	clip-path: polygon(0 0, 100% 0, 100% calc(100% - 30px), 0 100%);
	color: $light-text;
}

.segmentLabel {
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	z-index: 2;
}

.adjustTextToTop {
	margin-top: -30px;
}

.adjustTextToTopMost {
	margin-top: -60px;
}

.segmentValue {
	font-size: 1rem;
	font-weight: bold;
}

.industryColor {
	background-color: $industry-color;
}

.headCountColor {
	background-color: $headCount-color;
}

.keywordsColor {
	background-color: $keywords-color;
}

.languagesColor {
	background-color: $languages-color;
}

.DEColor {
	background-color: $deal-experience-color;
}

/* Horizontal Bars Section */
.horizontalBars {
	width: 70%;
	display: flex;
	flex-direction: column;
	gap: 10px;

	@media screen and (max-width: 1200px) {
		width: 720px;
	}

	@media screen and (max-width: 800px) {
		width: 340px;
	}
}

.barContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 64px;
	//border: 1px solid grey;

	.barInfoContainer {
		display: flex;

		.barInfo {
			display: flex;
			flex-direction: column;
			width: 50px;
			text-align: right;
			margin-right: 10px;

			.barCount {
				color: var(--Brand-Neutral-Grey-800, #2c3030);
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px;
			}

			.barPercentage {
				color: var(--Brand-Neutral-Grey-600-B, #4e5555);
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: 16px;
			}
		}
	}
}

.barLabel {
	width: 600px;
	display: flex;
	justify-content: space-between;
	color: var(--Brand-Neutral-Grey-800, #2c3030);
	text-align: right;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	padding-bottom: 3px;

	@media screen and (max-width: 1200px) {
		width: 500px;
	}

	@media screen and (max-width: 800px) {
		width: 210px;
		font-size: 11px;
	}

	.lightText {
		color: var(--Brand-Neutral-Grey-400, #868d8d);
	}
}

.faded {
	opacity: 0.5;
}

.actionList {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
	margin-left: 20px;
}

.bar {
	//width: 347px;
	background: rgba(218, 216, 215, 1);
	border-radius: 2px 0 0 2px;
	position: relative;
	height: 10px;
}

.barFill {
	height: 100%;
	border-radius: 2px 0 0 2px;
	transition: width 0.3s ease;
}

.marketSizeBar {
	background: $market-size-color;
}

.companyBar {
	background: $company-color;
}
