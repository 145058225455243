.checkboxListWrapperContainer {
	position: relative;
	display: flex;
}

.checkboxList {
	display: inline-flex;
	flex-direction: column;
	min-width: 100%;
	max-height: 280px;
	overflow: hidden auto;
	position: relative;
	margin-right: -12px;

	&:has(.accordion) {
		gap: 6px;
	}
}

.overlay {
	position: absolute;
	width: 100%;
	//min-height: 100%;
	top: -80px;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ffffff;
	opacity: 0.85;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.overlayText {
		position: relative;
		top: -20px;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		width: 100%;
		color: #808080;

		.loading {
			&:after {
				content: "....";
				width: 0;
				position: absolute;
				overflow: hidden;
				animation: loader-dots-animation 3s infinite;
				word-break: break-all;
			}

			@keyframes loader-dots-animation {
				0% {
					width: 0.1em;
				}
				50% {
					width: 1.2em;
				}
				100% {
					width: 0;
				}
			}
		}
	}
}

.accordion {
	.header {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.content {
		display: none;
		margin-left: 7px;
		//padding-left: 18px;
		//border-left: 1px dashed #dad8d7;
		position: relative;

		> label {
			margin-bottom: 5px;

			&:last-child {
				margin-bottom: 10px;
			}
		}

		&.expanded {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 5px;
		}

		&.singleChildren {
			margin-top: 8px;
			margin-left: 46px;
		}
	}

	&:last-child {
		.header {
			margin-bottom: 0;
		}
	}
}

.line {
	border-left: 1px dashed #dad8d7;
	height: 20px;
	position: relative;
}

.hrLine {
	border-top: 1px dashed #dad8d7;
	color: transparent;
	position: relative;
	//margin: 0 3px 0 -12px;
	margin-right: 4px;
	width: 32px;
	min-width: 32px;

	&.hasChildren {
		width: 11px;
		min-width: 11px;
	}
}

.childrenCheckboxList {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;

	&:first-child {
		margin-top: 5px;
	}
}

.content1stChild {
	position: relative;
	margin-left: 7px;
}

.content2ndChild {
	position: relative;
	margin-left: 30px !important;

	.hrLine {
		width: 32px;
		min-width: 32px;
	}
}

.content3rdChild {
	position: relative;
	margin-left: 100px !important;
}

.content1stLine {
	position: relative;
	left: 0;
}

.content2ndLine {
	position: relative;
	left: -24.5px;
}

.content3rdLine {
	position: relative;
	left: -96px;
}
