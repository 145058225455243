@import "styles/common.module.scss";

.cardContainer {
	justify-content: space-between;
	display: flex;
	flex-direction: column;
	font-weight: 700;
	font-size: 14px;
	background: #ffffff;
	align-items: center;
	border-radius: 5px;
	margin-bottom: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	min-width: 49.5%;
	width: 49.5%;

	@media (max-width: 1024px) {
		width: 100%;
		min-width: 100%;
	}
}

.titleContainer {
	display: flex;
	justify-content: space-between;
	font-weight: 600;
	font-size: 18px;
	padding: 0px 10px 7px 10px;
	border-bottom: 1px solid #000;
	width: 100%;
}

.iconContainer {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #000;
}

.contentContainer {
	padding: 15px 5px 5px 5px;
	border: 1px solid #f4f0ed;
	width: 100%;
	height: 100%;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 32% 32% 32%;
	border: 1px solid #f4f0ed;

	@media screen and (max-width: 1024px) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.companyContainer {
	border: 1px solid #f4f0ed;
	margin-bottom: 10px;
	height: 71px;
	padding: 7px 10px 10px 5px;
	position: relative;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 100%;
	cursor: pointer;
	z-index: 1;

	&__skeleton {
		display: none;
	}
}

.companyContainer {
	&:hover {
		.companyContainer__skeleton {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: transparent;
		}
	}
}

.companyName {
	color: #271b00;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
}

.logo {
	width: 32px;
	height: 32px;
	margin-right: 7px;
	border-radius: 50%;
	border: 1px solid #d1cdcc;
	object-fit: cover;
}

.userIcon {
	background-color: #000;
}

.candidateCount {
	position: absolute;
	bottom: 5px;
	right: 7px;
}

.industryCandidateCount {
	font-weight: 500;
	font-size: 18px;
}

.count {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	margin-left: 5px;
	margin-bottom: -5px;
}
