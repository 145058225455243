.header {
	padding: 8px 16px;
	border-radius: 4px 4px 0 0;
	background: var(--System-Silver-Chalice-100, #eeedec);
	border-top: 1px solid #eeedec !important;
	border-bottom: 1px solid #eeedec !important;

	th {
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
	}
}

.tableWrapper {
	.table {
		border: 1px solid #eeedec;

		thead tr {
			border: 1px solid #eeedec;
			border-radius: 4px 4px 0px 0px;
		}

		tbody tr {
			border: none !important;
		}
	}

	th,
	td {
		padding: 9px 16px !important;
	}

	tr td {
		border-bottom: 2px solid #eeedec;

		div:nth-child(1) {
			overflow: visible;
		}
	}

	tbody {
		tr {
			&:nth-child(even) {
				background: #f8f7f7;
			}

			td {
				&:last-child {
					border-left: 1px solid #eeedec;
				}

				&:nth-child(2) {
					div:nth-child(1) {
						display: flex;
						justify-content: center;
					}
				}
			}
		}
	}

	.tableCell {
		color: var(--Brand-Neutral-Grey-600-B, #4e5555);
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
	}

	.priorityCell {
		text-align: center;
		display: flex;
		height: 20px;
		min-width: 20px;
		max-width: max-content;
		padding: 4px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		border-radius: 4px;
		border: 1px solid var(--System-Silver-Chalice-200, #dad8d7);
	}
}

.statusDropdown {
	display: flex;
	padding: 4px 8px 4px 16px;
	justify-content: space-between;
	align-items: center;
	flex: 1 0 0;
	border-radius: 24px;
	border: 1px solid var(--System-Silver-Chalice-400, #b5b1b0);
}

.dropdownPlaceholder {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	width: 100%;

	color: var(--Brand-Neutral-Grey-900, #4e5555);

	span {
		color: var(--Brand-Neutral-Grey-400, #4e5555);
	}

	overflow: hidden !important;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.peopleCount {
	color: var(--Brand-Neutral-Grey-600-B, #4e5555);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
}
