.listedItems {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	overflow: hidden;
	min-height: 80vh;

	@media screen and (max-width: 1400px) {
		min-height: 450px;
	}
}

.filterSectionWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	gap: 8px;

	/* Inside auto layout */
	flex: none;
	order: 1;
	align-self: stretch;
	flex-grow: 0;

	.left {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 16px;
		color: #4e5555;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0;
		text-align: left;
	}

	.right {
		display: flex;
		flex-direction: row;
		gap: 16px;
	}
}

.headerInfoWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 16px;

	border-radius: 4px;
	background: var(--system-bw-white, #fff);

	/* Inside auto layout */
	flex: none;
	align-self: stretch;
	flex-grow: 0;

	.left {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 16px;
		color: #4e5555;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0;
		text-align: left;

		.filterCountInfo {
			display: flex;
			gap: 8px;
		}
	}

	.right {
		display: flex;
		flex-direction: row;
		gap: 16px;
	}
}

.toggleExpandButton,
.generateSummaryButton,
.viewButton {
	display: flex;
	align-items: center;
	gap: 5px;
	border-radius: 4px;
	padding: 6px 8px;
	background: transparent;
	color: #181b1a;
	height: 36px;
	border: 1px solid var(--system-silver-chalice-200, #dad8d7);

	.loading {
		pointer-events: none;
		background: #e5e6e6;
	}
}

.toggleExpandButton {
	border-radius: 4px;
	color: #4e5555;

	&.reverse {
		background: var(--brand-neutral-grey-50, #f7f8f8);

		svg {
			transform: rotate(180deg);
		}
	}
}

.buttonsGroup {
	display: flex;
	flex-direction: row;
	border-radius: 4px;

	.viewButton {
		border-radius: 0;
		color: #b0b5b4;
		border: 1px solid var(--system-silver-chalice-200, #dad8d7);

		svg path {
			fill: #b0b5b4;
		}

		&.active {
			border: 1px solid var(--system-silver-chalice-200, #181b1a);
			color: #181b1a;

			svg path {
				fill: #181b1a;
			}
		}

		&:first-child {
			border-radius: 4px 0 0 4px;
		}

		&:last-child {
			border-radius: 0 4px 4px 0;
		}
	}
}

.userSelectCheckboxContainer {
	display: flex;
	flex-direction: row;
	z-index: 3;
}

.dropdownButton {
	border: none !important;
	background: #ffffff !important;
	box-shadow: none !important;
	padding: 0;
	position: relative;
}

.customSelectionOption {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 32px;
	padding: 0 8px;
}

.paginationWrapper {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 60px;
}
