.container {
	overflow: hidden;
	border-radius: 4px;
	background: var(--system-bw-white, #fff);
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);
	padding-bottom: 50px;
	margin-bottom: 30px;

	h2 {
		font-size: 18px;
		font-weight: 500;
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		line-height: 28px;
		margin-left: 20px;
	}

	h3 {
		color: var(--Brand-Neutral-Grey-400, #868d8d);
		font-size: 18px;
		font-weight: 500;
		line-height: 28px;
		margin-left: 20px;

		span {
			color: var(--Brand-Neutral-Grey-600-B, #4e5555);
			font-weight: 600;
		}
	}

	hr {
		height: 1px;
		background: rgba(238, 237, 236, 1);
		border: none;
		margin: 0 0 20px 0;
		width: 100%;
	}

	.chartBox {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		gap: 20px;

		@media screen and (max-width: 1280px) {
			width: 96%;
		}

		@media screen and (max-width: 1279px) {
			flex-direction: column;
		}

		hr {
			background: #eeedec;
			height: 1px;
		}

		.titleSection {
			display: flex;
			justify-content: space-between;
			margin-right: 30px;

			@media screen and (max-width: 800px) {
				flex-direction: column;
				gap: 20px;
			}

			p {
				color: var(--Brand-Neutral-Grey-800, #2c3030);
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 28px;
				padding-left: 30px;
			}
		}

		.frequencyMovement {
			border-radius: 4px;
			border: 1px solid var(--System-Silver-Chalice-100, #eeedec);
			background: var(--system-bw-white, #fff);
			width: 50%;
			padding: 10px 0;

			@media screen and (max-width: 1024px) {
				width: 65%;
				padding: 0;
			}
			@media screen and (max-width: 700px) {
				width: 370px;
				padding: 0;
			}
		}

		.tenureCOntainer {
			border-radius: 4px;
			border: 1px solid var(--System-Silver-Chalice-100, #eeedec);
			background: var(--system-bw-white, #fff);
			width: 50%;
			padding: 10px 0;

			@media screen and (max-width: 1024px) {
				width: 65%;
				padding: 0;
			}
			@media screen and (max-width: 700px) {
				width: 370px;
				padding: 0;
			}
		}
	}
}

.barChartTitle {
	color: var(--Brand-Neutral-Grey-600-B, #4e5555);
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
}

.average {
	color: var(--Brand-Neutral-Grey-400, #868d8d);
	text-align: right;
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	align-items: center;
	display: inline-flex;
	gap: 5px;

	span {
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 13px;
		font-style: normal;
		font-weight: 600;
		line-height: 18px;
	}
}

.description {
	color: var(--Brand-Neutral-Grey-600-B, #4e5555);
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	margin-left: 30px;
}

.chartContainer {
	font-family: Arial, sans-serif;
	margin-top: 20px;
	margin-left: 30px;
	padding: 0;
	overflow-y: hidden;
}

.chartRow {
	display: flex;
	align-items: center;
}

.chartLabel {
	width: 135px;
	white-space: nowrap;
	font-size: 14px;
	color: rgba(78, 85, 85, 1);
	text-align: right;
	font-weight: 100;
	line-height: 20px;
	padding-right: 20px;
	position: relative;
	border-right: 1px solid rgba(131, 125, 124, 1);
	height: 26px;
}

.chartLabel:after {
	content: "-";
	position: absolute;
	right: -1px;
	color: rgba(131, 125, 124, 1);
}

.chartBar {
	border-radius: 4px 4px 0 0;
	background: var(--System-Charts-Di-Serria, #d8a864);
	height: 17px;
	position: relative;
	transition: width 1s ease-out;
	padding-right: 2px;
}

.chartValue {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	position: absolute;
	display: flex;
	align-items: center;
	top: 50%;
	right: 3px;
	transform: translate(0, -50%);
	font-size: 11px;
	font-weight: bold;
	color: #fff;
}
