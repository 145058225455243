@import "styles/common.module.scss";

.candidate {
	display: flex;
	flex-direction: column;
	background: #ffffff;
	justify-content: space-between;
	padding: 16px 35px;
	min-height: 230px;
	position: relative;
	margin-bottom: 1rem;
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);
	border-radius: 4px;
	border: none;

	&.outsideCompany {
		opacity: 0.85;
		//border: 1px solid #f1f1f1;
	}

	@media #{$viewportMobileS} {
		padding: 0.6em;
	}
	@media #{$viewportMobile} {
		padding: 0.6em;
	}
	@media #{$viewportMobileX} {
		padding: 0.8em;
	}
	@media #{$viewportSmall} {
		padding: 0.9em;
	}
	@media #{$viewportMedium} {
		padding: 1em;
	}
	@media #{$viewportTablet} {
		padding: 1em;
	}
	@media #{$viewportMinLarge} {
		padding: 16px 35px;
	}

	&.isRecommended {
		border: 1px solid var(--Brand-Tertiary-800, #d58a31);
	}
}

.container {
	//width: 79%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 16px;
}

.contentBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	//padding: 16px 0;
	gap: 16px;

	.row {
		display: flex;
		flex-direction: row;
	}
}

.infoBlockWithExperience {
	display: flex;
	flex-direction: column;
	gap: 24px;
	//padding-right: 16px;
}

.reportBlock {
	display: flex;
	flex-direction: column;
	border-left: 1px solid #eeedec;
	padding-left: 16px;
	align-items: flex-start;
	flex: 2 1 0;
	width: 100%;
}

.infoFirstRow,
.buttonRow {
	display: flex;
	flex-direction: row;
}

.outsideCompany {
	position: relative;
	padding: 22px 15px 20px 18px;

	.enhanceIcon {
		position: absolute;
		left: 0;
		top: 0;
		background: rgba(0, 0, 0, 0.09);
		border-radius: 0 0 8px 0;
		padding: 1.5px 6px 4.5px 6px;
	}
}

.apCandidateContainer {
	width: 100%;
}

//.rightContainer {
//  width: 21%;
//  display: flex;
//  flex-direction: column;
//  justify-content: space-between;
//}

// Stat Section

.experienceBlock {
	display: flex;
	flex-direction: column;
	width: 672px;
	gap: 40px;

	@screen and (min-width: 768px) and (max-width: 1024) {
		width: 60%;
	}

	@screen and (max-width: 767px) {
		width: 100%;
	}
}

.experienceContainer {
	border: 1px solid rgba(163, 156, 154, 0.7);
	border-radius: 3px;
	padding: 0.2rem 0.5rem 0.5rem 0.5rem;
	flex-direction: column;
	justify-content: space-between;
}

.experienceTitle {
	display: flex;
	font-size: 10px;
	font-weight: 400;
	text-transform: uppercase;
	color: rgba(0, 0, 0, 0.5);

	letter-spacing: 0.05em;
	margin-top: 5px;
	margin-bottom: 10px;
}

.experience {
	display: flex;
	justify-content: center;
	color: rgba(0, 0, 0, 1);
	border-radius: 2px;
	background: rgba(0, 0, 0, 0.05);
	padding: 4px 5px 2px 5px;
	margin-bottom: 6px;
}

.experienceName {
	display: flex;
	border-right: 0.5px solid #c8c8c8;
	padding-right: 4px;
	justify-content: center;
	align-self: center;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 8px;
}

.experienceDuration {
	display: flex;
	padding-left: 4px;
	justify-content: center;
	align-self: center;
	font-size: 10px;
	font-weight: 500;
}

.experienceName,
.experienceDuration {
	max-width: 50%;
	min-width: 50%;
}

.progressBar {
	margin-top: 20px;
}

.indexStat {
	margin-bottom: 1rem;
}

.indexIcon,
.employeeIcon {
	margin-right: 1rem;
}

.indexTitle {
	font-weight: 600;
	font-size: 0.95rem;
	color: $secondaryTextColor;
}

.employeeStat {
	margin-bottom: 1rem;
}

.employeeTitle {
	font-size: 0.85rem;
	line-height: 20px;
}

.infoTitle {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	color: $mainTextColor;

	.nameArea {
		display: flex;
		flex-direction: row;
	}

	.statusDropdown {
		display: flex;
		position: relative;
		border-radius: 4px;
		border: 1px solid var(--System-Silver-Chalice-200, #dad8d7);
		box-shadow: 0px 17px 33px -2px rgba(79, 75, 74, 0.05);
		height: 32px;

		.dropdownPlaceholder {
			width: 232px;
			//padding: 8px 8px 8px 16px;
			justify-content: space-between;
			align-items: center;
			//align-self: stretch;
			display: flex;
			flex-direction: row;

			color: var(--Brand-Neutral-Grey-300, #b0b5b4);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */

			&.hasStatus {
				color: #4e5555;
			}
		}
	}

	.info {
		display: flex;
		flex-direction: column;
	}

	.candidateName {
		cursor: pointer;
		color: #181b1a;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 28px; /* 140% */
		max-width: 310px;
	}

	.candidateCountry {
		color: #4e5555;
		font-size: 14px;
		font-style: normal;
		font-weight: 300;
		line-height: 20px; /* 142.857% */
	}
}

.infoDetail {
	color: $secondaryTextColor;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 17px;
	margin-bottom: 5px;
}

.infoCategory {
	margin-top: 0.7rem;
}

.infoCategoryTitle {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 200% */
	margin-bottom: 10px;
	letter-spacing: 0.05em;
	// text-transform: uppercase;

	/* SG Black 50% */

	color: rgba(0, 0, 0, 0.5);
}

.infoCategoryButton {
	background: $candidateIndustryButtonBackground;
	color: rgba(0, 0, 0, 0.5);
	height: $candidateIndustryButtonHeight;
	margin-right: 10px;
	border-radius: 3px;
	font-size: $candidateIndustryButtonFontSize;
	font-weight: 400;
	border: $candidateIndustryButtonBorder;
	margin-bottom: 10px;
	cursor: pointer;

	&.selected {
		background: #d6f0ee !important;
		color: #009882 !important;
	}
}

.buttonRow {
	justify-content: flex-end;
}

.logo {
	width: 56px;
	height: 56px;
	margin-right: 16px;
	border-radius: 50%;
	border: 1px solid #d1cdcc;
	object-fit: cover;
	cursor: pointer;
}

.iconLogo {
	min-width: 56px;
	min-height: 56px;
	margin-right: 16px;
	border-radius: 50%;
	padding: 16px;
	border: 1px solid #d1cdcc;
	object-fit: cover;
	cursor: pointer;
}

.informationContainer {
	//width: 60%;
	flex: 3 1 0;
	flex-direction: column;
}

.buttonText {
	display: flex;
	justify-content: center;
	align-items: center;

	color: rgba(0, 0, 0, 0.5);
	position: relative;
	//right: -10px;
}

.actionButtonContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between !important;
	align-items: flex-end;

	@media screen and (max-width: 767px) {
		flex-direction: column;
		gap: 10px;
	}

	.left {
		display: flex;
		gap: 10px;
	}
}

.disabled {
	cursor: unset !important;
}

.additionalMargin {
	margin-left: -10px;
}

.generateContactDetails {
	border-radius: 4px;
	border: 1px solid #dad8d7 !important;
	display: flex;
	padding: 4px 11px;
	justify-content: center;
	align-items: center;
	background: #fff;
	height: 32px;
	gap: 5px;
	color: #181b1a;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;

	&:hover {
		background: transparent;
	}

	&.locked {
		color: #c3c3c3;
	}

	@media screen and (max-width: 767px) {
		font-size: 12px;
		line-height: 14px;
		padding: 4px 6px;
	}
}

.jobTitle {
	/* S Regular */

	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	margin-top: 10px;
	/* Black */

	color: #000000;
}

.companyName {
	justify-content: center;
	font-style: normal;
	font-size: 14px;
	margin-top: 10px;
	margin-right: 10px;
}

.projectCompanyLogo {
	height: 32px;
	width: 32px;
	border-radius: 50%;
	border: 1px solid #fff;
	object-fit: cover;
	margin-right: 5px;

	&[src*="https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png"]
	{
		border: 1px solid #d1cdcc;
		border-radius: 30px;
	}
}

.pinButtonContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	background-color: #f9f9f9;
	border-radius: 50%;
	padding: 10px;
}

.pinIcon {
	color: #0c5850;
}

.scoreText {
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	display: flex;
	align-items: flex-end;
	text-align: right;
	margin-top: 20px;
	margin-bottom: 20px;
}

.contactDetails {
	display: flex;
	justify-content: flex-start;
	gap: 10px;
	//max-width: 800px;
	flex-wrap: wrap;
	font-size: 14px;
	padding: 16px 0;
	border-bottom: 1px solid #f8f7f7;
}

.dropdown {
	position: absolute;
	padding: 5px 15px;
	background-color: #fff;
	border: 1px solid #ccc;
	z-index: 111;
	gap: 10px;
}

.infoGroup {
	font-size: 14px;
	display: flex;
	justify-content: flex-start;
	line-height: 20px;
	font-weight: 400;
	color: #2c3030;
	gap: 5px;
	padding: 0 10px 10px 0;

	span {
		margin-left: 5px;
		border-radius: 5px;
		background-color: #f4f0ed;
		padding: 2px 6px;
		cursor: pointer;
	}
}

.noInfoGroup {
	color: #b0b5b4;
	font-size: 14px;
	display: flex;
	justify-content: flex-start;
	line-height: 20px;
	font-weight: 400;
	gap: 5px;
	padding: 0 10px 0 0;
}

.buttonGroup {
	font-style: normal;
	font-size: 18px;
	line-height: 22px;
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.jobDate {
	color: #808080;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	margin-left: 5px;
}

.previousCompany {
	font-size: 12px;
}

.previousCompanyName {
	font-weight: 600;
	font-size: 12px;
	margin-left: 5px;
	margin-right: 5px;
}

.previousJobTitle {
	font-size: 12px;
}

.previousJobDate {
	font-size: 12px;
}

.highlighter {
	background-color: rgba(250, 242, 224, 1);
	padding: 5px 3px;
}

.removeIcon {
	color: #c91515;
}

.showMoreButton {
	border-radius: 4px;
	border: 1px solid var(--system-silver-chalice-200, #dad8d7);
	display: flex;
	padding: 0;
	justify-content: center;
	align-items: center;
	background: #f7f8f8;
	color: #4e5555;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
	//max-height: 32px;
	height: 32px !important;
	width: 32px !important;
	min-width: 32px !important;
	max-width: 32px !important;

	svg {
		margin-right: 0;
	}

	&:hover {
		background: transparent;
		opacity: 0.8;
	}

	@media screen and (max-width: 767px) {
		font-size: 12px;
		padding: 4px 7px;
	}
}

.reverse {
	background: var(--brand-neutral-grey-50, #fff);

	svg {
		transform: rotateZ(180deg);
	}
}

.upgradeView {
	pointer-events: none;
	position: relative;

	> * {
		opacity: 0.32;
		filter: blur(6px);
	}
}

.recommendedIcon {
	position: absolute;
	z-index: 1;
	top: 12px;
	left: 60px;
}

.rejectButton {
	//margin-top: 1rem;
	display: flex;
	align-items: center;
	gap: 6px;
	border-radius: 4px;
	max-height: 32px;
	padding: 6px 12px;
	min-width: auto;
	border: 1px solid rgba(201, 21, 21, 1);

	@media screen and (max-width: 767px) {
		font-size: 12px;
		padding: 4px 6px;
	}
}

.skeleton {
	width: 670px;
	display: flex;
	gap: 5px;
	justify-items: flex-start;
}
