.container {
	width: 100%;
	margin-top: 38px;
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.noQueryMatch {
	text-align: center;
	margin-top: 15%;

	h4,
	p {
		color: #424242;
	}
}

.headerSection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	.title {
		color: var(--Brand-Neutral-Grey-400, #868d8d);
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 28px; /* 140% */
	}

	@media screen and (max-width: 767px) {
		gap: 10px;
	}

	.multiSelect {
		border: none;
	}

	.search_container {
		cursor: text;
		display: flex;
		padding: 12px 16px;
		background: #fff;
		height: 40px;
		border-radius: 3px;
		align-items: center;
		border: 1px solid #cbc5c2;
		min-width: 290px;
		margin-right: 10px;

		@media only screen and (max-width: 767px) {
			width: 100%;
		}

		&__active {
			border-radius: 4px 4px 0px 0px;
			box-shadow: 0px 9px 31px rgba(0, 0, 0, 0.04);
		}

		&__icon {
			margin-right: 6px;
		}

		&__inp {
			margin: 0;
			padding: 0;
			width: 100%;
			border: none;
			outline: none;
			font-size: 14px;
			font-weight: 500;
			line-height: 24px;
			font-family: "Inter", sans-serif;
			background: transparent;
		}

		&__inp::placeholder {
			color: #b0b5b4;
		}
	}
}

.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.noResultFound {
	padding: 20px;
	background: #fff;

	span {
		font-weight: 500;
		color: #0c5850;

		&:hover {
			cursor: pointer;
		}
	}
}
