@import "../../../../styles/common.module";

.industryReportContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	background-color: #fff;
	padding: 12px;
	box-shadow: 0px 9px 31px rgba(0, 0, 0, 0.04);
	border-radius: 4px;

	@media (max-width: 1024px) {
		padding: 20px;
		flex-direction: column;
		justify-content: center;
		gap: 10px;
	}
}

.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}
