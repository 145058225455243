.wrapper {
	position: relative;

	&_head {
		display: flex;
		max-height: 42px;
		padding: 8px 10px;
		align-items: center;
		min-width: max-content;
		box-sizing: border-box;
		border: 1px solid #e9e9e9;

		&__opened {
			background: #f6f6f6;
		}

		&__no_border {
			border: none;
		}

		&__group {
			&_left {
				border-right: none;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;

				@media screen and (max-width: 1024px) {
					border-right: 1px solid #e9e9e9;
					border-radius: 4px;
				}
			}

			&_center {
				border-radius: 0;
			}

			&_right {
				border-left: none;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}

		&__text {
			display: flex;

			&__title {
				margin: 0;
				font-size: 16px;
				font-weight: 400;
				color: #404040 !important;
				line-height: 24px;
				font-family: "Inter", sans-serif;
			}
		}

		&__subTitle {
			color: #868d8d;
			font-size: 12px;
			font-weight: 500;
			line-height: 16px;
			margin: 0;
			max-width: 158px;
			width: 100%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

			@media screen and (max-width: 1024px) {
				max-width: 125px;
				font-size: 11px;
			}
		}

		&__left_icon {
			width: 24px;
			height: 24px;
			margin-right: 8px;

			svg {
				width: 24px;
				height: 24px;
			}
		}

		&__right_icon {
			width: 24px;
			height: 24px;
			margin-left: 30px;
			transition: transform 0.2s linear;

			path {
				stroke: #808080;
				stroke-width: 0;
			}

			&__rotated {
				transform: rotateX(180deg);
			}
		}

		&__arrow {
			margin-left: 8px;

			path {
				stroke: #808080;
			}
		}
	}
}

.list {
	right: 0;
	top: 55px;
	display: flex;
	padding: 16px;
	position: absolute;
	border-radius: 4px;
	background: #ffffff;
	flex-direction: column;
	max-width: max-content;
	min-width: max-content;
	align-items: flex-start;
	box-shadow: 0px 0px 31px rgba(0, 0, 0, 0.08);

	@media screen and (max-width: 425px) and (min-width: 374px) {
		z-index: 101;
		padding-right: 0;
		max-width: 300px;
		min-width: 300px;
	}

	@media screen and (max-width: 375px) and (min-width: 321px) {
		z-index: 101;
		padding-right: 0;
		max-width: 250px;
		min-width: 250px;
	}

	@media screen and (max-width: 320px) {
		z-index: 101;
		padding-right: 0;
		max-width: 220px;
		min-width: 220px;
	}

	&__border {
		gap: 16px;
		width: 100%;
		display: flex;
		padding-top: 16px;
		flex-direction: column;
		align-items: flex-start;
		border-top: 1px solid #e6e6e6;
	}

	&__gap {
		gap: 16px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-top: 0 !important;
		border-top: none !important;
	}

	&__nth {
		gap: 16px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	&__arrow {
		top: -8px;
		right: 18px;
		position: absolute;
		padding-bottom: 0 !important;
		border-bottom: none !important;
	}

	&__item {
		width: 100%;
		display: flex;
		font-size: 14px;
		font-weight: 400;
		color: #404040;
		line-height: 22px;
		align-items: center;

		&_container {
			gap: 4px;
			display: flex;
			margin-right: 0;
			margin-left: 29px;
			align-items: center;
		}

		&__badge {
			margin: 0;
			font-size: 12px;
			font-weight: 600;
			color: #312200;
			padding: 2px 4px;
			line-height: 20px;
			border-radius: 4px;
			background: #f4f0ed;

			span {
				color: #808080;
			}
		}

		&__red {
			color: #c91515;
		}

		&__icon {
			margin-right: 8px;

			&__red {
				svg {
					path {
						fill: #c91515;
					}
				}
			}
		}

		&__empty {
			font-size: 14px;
			font-weight: 400;
			color: #a39c9a;
			line-height: 22px;
		}
	}

	&__scroll {
		gap: 16px;
		width: 350px;
		display: flex;
		padding-right: 16px;
		flex-direction: column;
		height: 350px;
		overflow-y: auto;

		&__item {
			text-align: left;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&_order {
				order: -1;
			}

			&_left {
				display: flex;
				margin-right: 26px;
				align-items: center;

				&_img {
					width: 24px;
					height: 24px;
					min-width: 24px;
					min-height: 24px;
					object-fit: cover;
					margin-right: 8px;
					border-radius: 50%;
					border: none !important;
					padding-bottom: 0 !important;
				}

				&_texts {
					&_cont {
						display: flex;
						align-items: center;

						&_name {
							white-space: nowrap;
							margin: 0;
							font-size: 14px;
							font-weight: 400;
							color: #404040;
							line-height: 22px;

							&_active {
								font-weight: 500;
							}
						}

						&_badge {
							width: 6px;
							height: 6px;
							margin-right: 8px;
							border-radius: 50%;
							background: #3dac57;
						}
					}

					&_sub_name {
						font-size: 12px;
						font-weight: 400;
						color: #808080;
						line-height: 20px;
						font-style: normal;
					}
				}
			}

			&_right {
				display: flex;
				align-items: center;

				&_close {
					font-size: 12px;
					font-weight: 600;
					color: #808080;
					line-height: 20px;
					margin-right: 16px;
					font-family: "Inter";
				}
			}
		}

		&__children {
			margin: 0 auto;
		}
	}
}

.tooltip {
	background-color: #ffffff;
	box-shadow: 0px 0px 31px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	opacity: 1;
	width: 280px;
	padding: 16px;
	z-index: 1;

	@media (max-width: 753px) {
		left: 40% !important;
	}
}

.line {
	margin: 0;
	width: 100%;
	height: 1px;
	border: 0.5px solid #e6e6e6;
}

.noProject {
	color: #cbcdcd !important;
}
