.content {
	font-family: "Inter", sans-serif;
	position: relative;
	z-index: 0;
	background: #ffffff;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding: 24px;
	border-radius: 4px;

	@media screen and (max-width: 767px) {
		flex-direction: column;
	}
}

.marketMapMenu {
	font-family: "Inter", sans-serif;
	position: absolute;
	z-index: 999 !important;
	top: -3px;
	right: 20px;
	width: 165px;
	background: #ffffff;
	border: 1px solid rgba(163, 156, 154, 0.5);
	padding: 7px 0 7px 15px;

	.menuItem {
		font-family: "Inter", sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: rgba(0, 0, 0, 0.75);
		padding: 7px 15px 7px 7px;
		cursor: pointer;
	}
}

.textContainer {
	font-family: "Inter", sans-serif;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media screen and (max-width: 767px) {
		width: 100%;
		gap: 10px;
	}
}

.openReportButton {
	border: 1px solid #0c5850;
	background-color: transparent;
	border-radius: 4px;
	padding: 8px 8px 8px 16px;
	font-size: 14px;
	color: #0c5850;
	font-weight: 600;
	margin-top: -10px;

	span {
		margin-right: 5px;
	}
}

.listInfo {
	width: 100%;

	h2 {
		font-size: 20px;
		cursor: pointer;
		font-weight: 600;
		width: fit-content;
		margin: 0;
	}
}

.statsContainer {
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: 767px) {
		flex-direction: column;
	}

	.marketMapButtons {
		grid-template-columns: auto auto auto;
		margin-top: 30px;
		display: grid;
		gap: 25px;
		margin-right: -80px;

		@media screen and (max-width: 767px) {
			grid-template-columns: auto auto;
		}

		.statItem {
			font-family: "Inter", sans-serif;
			font-size: 14px;
			color: rgba(0, 0, 0, 0.75);
		}

		.statItemWithBorderRight {
			font-family: "Inter", sans-serif;
			font-size: 14px;
			color: rgba(0, 0, 0, 0.75);
			border-right: 1px solid #eeedec;
			padding: 0 20px 0 0;
			height: 25px;
		}
	}

	.statInfo {
		margin-top: 10px;
		display: flex;
		gap: 20px;
		font-size: 13px;
		justify-content: flex-start;

		color: var(--Brand-Neutral-Grey-400, #868d8d);
		font-style: normal;
		font-weight: 600;
		line-height: 18px; /* 138.462% */

		.count {
			color: var(--Brand-Neutral-Grey-600-B, #4e5555);
			font-weight: 700;
		}
	}

	.dates {
		display: flex;
		flex-direction: row;
		gap: 16px;
		align-items: center;
		margin-top: 16px;
		color: var(--Brand-Neutral-Grey-400, #868d8d);
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px; /* 133.333% */
	}
}

.menuDots {
	border: 1px solid #eeedec;
	border-radius: 4px;
	position: relative;
	right: 10px;
	width: 35px;
	height: 35px;
	cursor: pointer;
	color: #868d8d;
	padding: 5px;

	&:hover {
		background-color: rgba(200, 200, 200, 0.15);
		border-radius: 50%;
		border: 2px solid rgba(200, 200, 200, 0.15);
	}

	&:focus {
		background-color: rgba(200, 200, 200, 0.15);
		border-radius: 50%;
	}
}

.active {
	display: block;
	position: relative;
}

.hidden {
	display: none;
}
